import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Content from '../components/content'

export const pageQuery = graphql`
  query AboutQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 400, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
		linkList
      }
    }
  }
`
const AboutPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

	return (
		<Layout className="page">
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<section className="article-header">
				<h1>{frontmatter.title}</h1>
			</section>
			{frontmatter.featuredImage ?
				(
					<div className="grids col-1 sm-2">
						<Img
            			  fluid={frontmatter.featuredImage.childImageSharp.fluid}
            			  alt={frontmatter.title + ' - Featured image'}
            			  className="featured-image"
						  fadeIn={false}
            			/>
						<div className="wrapper">
							<article dangerouslySetInnerHTML={{ __html: html }} />
						</div>
					</div>
				) :
					<div>
						<article dangerouslySetInnerHTML={{ __html: html }} />
						<div className='information-card'>
							<p>参考サイト</p>
							<Content source={frontmatter.linkList} />
						</div>
      				</div>
			}
		</Layout>
	)
}

export default AboutPage
